module.exports = {
  //themed images start
  navbar_expanded_logo_icon: require("./themeImages/default/chkfakeLogoExpanded.png"),
  site_logo_large: require("./themeImages/default/ChkfakeLogoLarge.png"),
  site_logo: require("./themeImages/default/chkfakeLogo.png"),
  //themed images end
  profile_pic: require("./common/profile.png"),
  logo: require("./common/logo.svg"),
  graph: require("./graph/graph.png"),
  kingfisher: require("./common/kingfisher.png"),
  nav_dashboard: require("./common/navigation/dashboard@2x.png"),
  nav_engagement: require("./common/navigation/engagementView@2x.png"),
  nav_gear: require("./common/navigation/gear@2x.png"),
  nav_var_view: require("./common/navigation/verificationView@2x.png"),
  back_arrow: require("./incidentReporting/back-arrow.png"),
  share_icon: require("./incidentReporting/share.png"),
  export_icon: require("./incidentReporting/export.png"),
  forward_arrow: require("./incidentReporting/forward-arrow.png"),
  navigation_icon: require("./incidentReporting/navigation.png"),
  thumbs_down_in_circle: require("./incidentReporting/thumbsDownInCircle.png"),
  thumbs_up_in_circle: require("./incidentReporting/thumbsUpInCircle.png"),
  interm: require("./incidentReporting/interm.png"),
  noImageShared: require("./incidentReporting/noImageShared.png"),
  prevPageArrow: require("./common/prevPageArrow.png"),
  pagePlaceholder: require("./common/pagePlaceholder.png"),
  antiCounterfeitPlaceholder: require("./common/anti_counterfeit.png"),
  incidentViewPlaceholder: require("./common/Incident_View.png"),
  unsupportedBrowser: require("./common/unsupported_browser.jpg"),
  red_tick: require("./common/red_tick.png"),
  green_tick: require("./common/green_tick.png"),
  dashboard: {
    lock_icon: require("./dashboard/widget_lock.png"),
    header_icon: require("./dashboard/header/Group.png"),
    summary: {
      noVerification: require("./dashboard/summary/noVerification.png"),
      percentageCounterfeits: require("./dashboard/summary/percentageCounterfeits.png"),
      numberOfReportedIncidents: require("./dashboard/summary/numberOfReportedIncidents.png"),
      upArrowGreen: require("./dashboard/summary/upArrowGreen.png"),
      upArrowRed: require("./dashboard/summary/upArrowRed.png"),
      downArrowGreen: require("./dashboard/summary/downArrowGreen.png"),
      downArrowRed: require("./dashboard/summary/downArrowRed.png"),
      //themed images start
      numberOfActiveUsers: require("./dashboard/summary/numberOfActiveUsers.png")
      //themed images end
    },
    filters: {
      //themed images start
      brand_filter: require("./dashboard/filters/brandsActive@2x.png"),
      region_filter: require("./dashboard/filters/locationActive@2x.png"),
      user_filter: require("./dashboard/filters/consumerActive@2x.png"),
      date_filter: require("./dashboard/filters/calendarActive@2x.png")
      //themed images end
    },
    grid: {
      right_menu: require("./dashboard/grid/right_menu.png"),
      sort_up: require("./dashboard/grid/sort_up.png"),
      sort_down: require("./dashboard/grid/sort_down.png")
    }
  },
  mondelez: {
    appstore_icon: require("./mondelez/appstore_icon.png"),
    chkfake_logo: require("./mondelez/chkfake_logo.png"),
    googleplay_icon: require("./mondelez/googleplay_icon.png"),
    mondelez_logo: require("./mondelez/mondelez_logo.png"),
    mondelez_video_image: require("./mondelez/mondelez_video_image.png")
  },
  notification_in_active: require("./common/menu/notificationInactive.png"),
  profile_in_active: require("./common/menu/profileInactive.png"),
  alert_icon: require("./common/alerts/Alert.png"),
  clear_icon: require("./common/alerts/Clear.png"),
  counterfeit_Alert_icon: require("./common/alerts/Counterfeit_Alert.png"),
  code_scan_icon: require("./common/alerts/code_scan_icon.png"),
  clear_small: require("./common/alerts/clearSmall.png")
};
